import settings from '@/settings'

/**
 * 操作紀錄
 */
class _Operation {
  // 查詢操作紀錄
  getRecord(params) {
    return axios.get(settings.api.fullPath + `/operation/record`, {
      params: params
    })
  }

  // 使用者操作紀錄下載
  downloadRecord(params) {
    return axios.get(settings.api.fullPath + `/operation/report/record`, {
      params: params
    })
  }
}

var operation = new _Operation()
export default operation
