<template>
  <div class="pa-5">
    <div class="font-weight-bold accent--text text-h5">操作紀錄</div>
    <v-row
      class="mt-1"
      :class="{ 'mb-1': $vuetify.breakpoint.xs }"
      justify="end"
    >
      <v-col cols="12" sm="2" md="1">
        <v-select
          v-model="selectedDirection"
          :items="directions"
          hide-details=""
          solo
          flat
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-text-field
          v-model="searchAccount"
          hide-details=""
          solo
          flat
          placeholder="查詢帳號"
        />
      </v-col>
      <v-col cols="12" sm="6" md="2">
        <v-menu
          ref="menu"
          v-model="datePicker.show"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRange"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              :rules="dateRangeRules"
              solo
              flat
              placeholder="時間區間"
              clearable
              @click:clear="datePicker.range = []"
            >
            </v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="datePicker.range"
            no-title
            range
            scrollable
            color="primary"
            :max="moment().format('YYYY-MM-DD')"
            :min="
              moment().subtract(3, 'years').add(1, 'days').format('YYYY-MM-DD')
            "
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn
          class="py-6 px-8 accent--text font-weight-bold"
          color="ems-aquamarine"
          block
          @click="search"
          >查詢</v-btn
        >
      </v-col>
      <v-col cols="12" sm="auto"
        ><v-btn
          class="py-6 px-8 font-weight-bold"
          color="primary"
          block
          :loading="loadingDownload"
          @click="download"
          >下載報表</v-btn
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="result"
      :loading="loading"
      loading-text="載入中..."
      id="table"
      :page.sync="query.index"
      :items-per-page="query.paginateCount"
      hide-default-footer
      no-data-text="( 無資料 )"
    >
      <template v-slot:[`item.requestBody`]="{ item }">
        <v-tooltip left class="pa-2" max-width="350" :value="item.id === 52">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <v-icon color="grey">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <div class="tooltip-content">{{ formatBody(item.requestBody) }}</div>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="query.index"
        :length="totalPage"
        color="accent"
        @input="search"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import Operation from '@/api/Operation'

export default {
  data() {
    return {
      headers: [
        { text: '#', value: 'id' },
        { text: '帳號', value: 'account' },
        { text: 'httpMethod', value: 'httpMethod' },
        { text: 'uri', value: 'uri' },
        { text: 'requestBody', value: 'requestBody' },
        { text: 'responseStatus', value: 'responseStatus' },
        { text: 'description', value: 'description' },
        { text: 'IP', value: 'ip' },
        { text: 'apVersion', value: 'apVersion' },
        { text: 'createdDate', value: 'createdDate' }
      ],
      directions: [
        { text: '升冪', value: 'ASC' },
        { text: '降冪', value: 'DESC' }
      ],
      selectedDirection: 'ASC',
      searchAccount: '',
      query: {
        index: 1,
        paginateCount: 10
      },
      datePicker: {
        show: false,
        range: []
      },
      dateRangeRules: [
        (value) => {
          if (value) {
            return value.indexOf('~') > -1 || '請選擇時間結束日期'
          } else return true
        }
      ],
      loading: false,
      result: [],
      totalPage: 1,
      loadingDownload: false
    }
  },
  computed: {
    dateRange() {
      if (this.datePicker.range.length === 0) return ''
      const dates = this.datePicker.range
        .slice()
        .sort(
          (a, b) =>
            parseFloat(moment(a).format('x'), 10) -
            parseFloat(moment(b).format('x'), 10)
        )
      return dates.join('~')
    },
    params() {
      return {
        ...this.query,
        startDate: this.dateRange.split('~')[0],
        endDate: this.dateRange.split('~')[1] || '',
        account: this.searchAccount,
        direction: this.selectedDirection
      }
    }
  },
  methods: {
    async search() {
      try {
        this.loading = true
        const { data } = await Operation.getRecord(this.params)
        this.result = data.data
        this.totalPage = data.allIndex || 1
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async download() {
      try {
        this.loadingDownload = true
        const { data } = await Operation.downloadRecord(this.params)
        const url = window.URL.createObjectURL(
          new Blob([(data ? '\ufeff' : '') + data], {
            type: 'text/csv;charset=utf-8;'
          })
        )
        const link = document.createElement('a')
        link.href = url
        let fileName = this.getResponseFilename()
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingDownload = false
      }
    },
    getResponseFilename() {
      let fileName = `操作紀錄`
      if (this.dateRange.length)
        fileName += `_${this.params.startDate}_${this.params.endDate}`
      return `${fileName}.csv`
    },
    formatBody(value) {
      let returnValue = '-'
      try {
        returnValue = JSON.stringify(JSON.parse(value), '', 2)
      } catch {
        returnValue = value ? value : '-'
      } finally {
        return returnValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
*::v-deep {
  label.v-label {
    color: var(--v-accent-base);
  }
  &.v-data-table {
    background-color: transparent !important;
  }
  &.v-data-table > .v-data-table__wrapper > table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    th,
    td {
      border-bottom: none !important;
    }
    thead > tr > th {
      background-color: #acc0dd;
      color: #fff;
    }
    td {
      background-color: rgba(255, 255, 255, 0.7);
      color: var(--v-accent-base);
      font-size: 1rem !important;
    }
    tr.errorClass {
      border: 1px solid #ed3a4b;
      td {
        background-color: rgb(252, 224, 224, 0.7);
      }
    }
  }
}

.tooltip-content {
  white-space: pre-line;
  word-break: break-word;
}
</style>
